import { useParams } from 'react-router-dom';

import {
  useBrand,
  useLocalBrandSpecials,
} from '@jane/shared-ecomm/data-access';
import { useUserPreferences } from '@jane/shared-ecomm/providers';
import { getJaneGoldFAQPath } from '@jane/shared-ecomm/util';
import { LoadingWrapper } from '@jane/shared/components';
import { useShouldShowGold } from '@jane/shared/hooks';
import {
  Box,
  Flex,
  JaneGoldLogo,
  Link,
  PartnerLogo,
  Typography,
  useDesktopMediaQuery,
} from '@jane/shared/reefer';

export const BrandTitle = () => {
  const isDesktop = useDesktopMediaQuery({});
  const { brand_id, store_id } = useParams<{
    brand_id: string;
    store_id: string;
  }>();

  const { data: brandData, isLoading: isLoadingBrand } = useBrand(
    brand_id || ''
  );

  const { userLocation, userPreferences } = useUserPreferences();
  const { localBrandSpecials: brandSpecials, isLoading: isLoadingSpecials } =
    useLocalBrandSpecials({
      brandIds: [Number(brand_id)],
      storeIds: [Number(store_id)],
      enabled: !!brand_id && !!store_id,
      userLocation,
      userPreferences,
      userSegments: [],
    });

  const shouldShowGold = useShouldShowGold({ brandSpecials });

  const isLoading = isLoadingBrand || isLoadingSpecials;

  return (
    <LoadingWrapper isLoading={isLoading} variant="menu-title">
      <Flex alignItems={isDesktop ? 'center' : 'flex-start'}>
        <PartnerLogo
          variant="brand"
          name={brandData?.name || ''}
          image={brandData?.logo_url}
          size={isDesktop ? 'lg' : 'sm'}
        />

        <Box ml={isDesktop ? 24 : 16}>
          <Typography branded variant="title-bold">
            {brandData?.name}
          </Typography>

          {shouldShowGold && (
            <Flex
              alignItems={isDesktop ? 'center' : 'flex-start'}
              pt={isDesktop ? 8 : 4}
              flexDirection={isDesktop ? 'row' : 'column'}
            >
              <JaneGoldLogo height={26} mr={8} />
              <Typography color="text-light">
                Earn exclusive cash back rewards from{' '}
                {brandData?.name || 'this brand'} with{' '}
                <Link branded href={getJaneGoldFAQPath()} color="text-light">
                  Jane Gold
                </Link>
              </Typography>
            </Flex>
          )}
        </Box>
      </Flex>
    </LoadingWrapper>
  );
};
